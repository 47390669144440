/** @jsx jsx */
import React from 'react';
import { jsx, Flex, Box } from 'theme-ui';
import { Container } from '@sprinklr/shared-lib';
import RagyImage from '../../../assets/images/ragy-thomas.png';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { ContainerPadding } from '@sprinklr/shared-lib/@types/types';

const RagyLetter: React.FC<{ padding: ContainerPadding }> = ({ padding }) => {
  const { t } = useI18next();
  const [pt, pb] = useContainerPadding(padding);
  return (
    <Container
      containerSx={{
        pt: pt,
        pb: pb,

        '@media only screen and (max-width: 919px))': {
          //hardcoded based on specific medium fluid Document params
          px: ['22px', '80px'],
        },
      }}
    >
      <Flex
        sx={{
          pb: ['19px', '49px', '51px'],
          maxWidth: '900px',
          margin: '0 auto',
        }}
      >
        <Box
          className="person-image-container"
          sx={{
            flexShrink: 0,
            borderRadius: '50%',
          }}
        >
          <img
            src={RagyImage}
            alt={'Ragy Thomas Portrait'}
            sx={{
              height: ['60px', '100px'],
              width: ['60px', '100px'],
            }}
          />
        </Box>
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative',
            fontWeight: 'body',
            ml: [3, '30px'],
            top: ['-2px', '-6px'], // adjust to optically look correct and accomodate for lineHeight
          }}
        >
          <strong
            sx={{
              fontSize: [3, 10, 15],
              lineHeight: ['22px', '48px', '56px'],
              fontWeight: 'bold',
            }}
          >
            {t('A letter from Ragy Thomas')}
          </strong>
          <Box
            sx={{
              color: 'text',
              fontWeight: 'medium',
              fontSize: [1, 2],
              lineHeight: ['18px', '26px'],
            }}
          >
            <span>{t('Founder, Chairman & CEO')}</span>
          </Box>
        </Flex>
      </Flex>
    </Container>
  );
};

export default RagyLetter;
